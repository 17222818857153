import { createGlobalStyle } from 'styled-components';
import bg from '../images/bg.png';
import colors from './colors';

const GlobalStyles = createGlobalStyle`
    body {
        background: #fff;
        margin:0 !important;
        font-family: 'Roboto', sans-serif;
        width:100vw;
        height: 100vh;

        background: url(${bg});
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        font-size: 1em;

        color: ${colors.main_text};
    }
    a {
        text-decoration: none;
        transition: all 0.25s ease-out 0s;
        &:hover {
            transition: all 0.25s ease-out 0s;
        }
    }
    h1 {
        font-size: calc(2vw + 2em);
    }

    main {
        width: 100vw;
        height: 100vh;
        overflow: auto;
    }
`;

export default GlobalStyles;
